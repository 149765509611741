<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
				The content provided on <a href="https://gbapks.org.in/">https://gbapks.org.in/</a> is for informational purposes only. GB WhatsApp is a third-party modified version of WhatsApp that offers additional features and customization options not available in the official app. While we strive to provide accurate and up-to-date information about GB WhatsApp and related apps, we do not guarantee the security, reliability, or legality of using modified versions of WhatsApp.
			</p>
			<p>
				We are not responsible for any damages, loss of data, or security breaches that may occur as a result of using these apps.
			</p>
			<p>
				By using this website, you acknowledge that you are solely responsible for your actions and understand the potential risks involved with third-party apps like GB WhatsApp.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'Thanks for visiting our site and reading the disclaimer in this page.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapks.org.in/disclaimer/"
            }]
	},
}
</script>
  